import React                from 'react';
import PropTypes            from 'prop-types';
import {render}             from 'react-dom';
import Tile                 from './Tile';
import Flux                 from '../../flux/Flux';
import GuestAccess          from '../../utils/GuestAccess';
import ReloadHelper         from '../../utils/ReloadHelper';
import GlobalEventHandler   from '../../utils/GlobalEventHandler';
import PjaxWrapper          from './../../utils/PjaxWrapper';
import PlateFactory 		from './Plate/PlateFactory';
import { generateKey } 		from '../../utils/CommonUtils';
import withSuspense         from '../HigherOrderComponents/Utility/withSuspense';

const ActorPlaceholderTile = React.lazy(() => import('./ActorPlaceholderTile'));
const VideoPlayer          = React.lazy(() => import('../../components/Gallery/VideoPlayer').then(module => ({default: module.VideoPlayer})));
const Viewer               = React.lazy(() => import('../../components/Gallery/Viewer').then(module => ({default: module.Viewer})));


const TILEGRID_MODE_GRID = 'TILEGRID_MODE_GRID';

let pictureIndex = 0;

class TileGrid extends React.Component {

	static getGalleryContainer() {
		const elemId         = 'layer-album-modal';
		let galleryContainer = document.getElementById(elemId);

		if (!galleryContainer) {
			galleryContainer    = document.createElement('div');
			galleryContainer.id = elemId;
			document.body.appendChild(galleryContainer);
		}

		return galleryContainer;
	}

	constructor(props) {
		super(props);

		this.eventTracker = Flux.Tracker.getEventTracker();

		// default crop state - from props
		this.state = {
			crop:        this.props.gridConfig.hasOwnProperty('crop') ? this.props.gridConfig.crop : true,
			windowWidth: Flux.Browser.getWindowSize().width,
		};

		this.onButtonClickFn           = this.onButtonClickFn.bind(this);
		this.onClickFn                 = this.onClickFn.bind(this);
		this.onOpenViewer              = this.onOpenViewer.bind(this);
		this.onOpenPinnedPictureViewer = this.onOpenPinnedPictureViewer.bind(this);
		this.getPinnedPhotosParams     = this.getPinnedPhotosParams.bind(this);
		this.renderPinnedPictureViewer = this.renderPinnedPictureViewer.bind(this);
		this.renderViewer              = this.renderViewer.bind(this);
		this.onClickVideoTile          = this.onClickVideoTile.bind(this);
		this.onResize                  = this.onResizeHandler.bind(this);
	}

	componentDidMount() {
		if (this.props.tileGridId) {
			this.onCropToggle = this.onCropToggleHandler.bind(this);

			const scrollPosition        = Flux.TileGrid.getScrollPositionById(this.props.tileGridId);
			const restoreScrollPosition = Flux.TileGrid.getRestoreScrollPosition();

			if (scrollPosition && restoreScrollPosition) {
				PjaxWrapper.setIgnoreNextPjaxScroll();
				window.scrollTo(0, scrollPosition);

				Flux.TileGrid.setRestoreScrollPosition(false);
				Flux.TileGrid.resetBackLink(this.props.tileGridId);
				Flux.TileGrid.resetScrollPosition(this.props.tileGridId);
			}

			// listen for crop change
			Flux.TileGrid.addOnGridCropListener(this.onCropToggle);
		}

		Flux.Browser.addWindowResizeListener(this.onResize);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.gridConfig.crop !== this.props.gridConfig.crop) {
			this.setState({crop: this.props.gridConfig.crop});
		}
	}

	componentWillUnmount() {
		Flux.Gallery.removeGalleryChangeListener(this.renderViewer);
		Flux.Gallery.removePinnedPhotosChangeListener(this.renderPinnedPictureViewer);
		Flux.Browser.removeWindowResizeListener(this.onResize);

		if (this.props.tileGridId) {
			Flux.TileGrid.setScrollPosition(this.props.tileGridId, window.pageYOffset);
			Flux.TileGrid.setBackLink(this.props.tileGridId);

			// stop listening for crop change
			Flux.TileGrid.removeOnGridCropListener(this.onCropToggle);
		}
	}

	onResizeHandler() {
		this.setState({
			windowWidth: Flux.Browser.getWindowSize().width,
		});
	}

	/**
	 * @param {String} tileGridId
	 * @param {Boolean} crop
	 */
	onCropToggleHandler(tileGridId, crop) {
		// skip if no ID match
		if (tileGridId !== this.props.tileGridId) {
			return;
		}

		this.setState({crop});
	}

	onButtonClickFn(e, item) {
        if (item.isLinkOpeningGallery) {
            e.preventDefault();
			this.onOpenViewer(0, item);
			e.preventDefault();
			e.stopPropagation();
		} else {
            if (typeof this.props.onButtonClickFn !== 'function') {
                if (typeof item !== 'undefined' && item.mediaType && item.mediaType === Flux.Constants.MediaTypes.PHOTO_ALBUM && !item.isLinkOpeningGallery) {
                    // scroll to content
                    const targetUrl = item.targetUrl + '#pjax-scrollto';

                    if (Flux.Guest.isLoggedIn() && !item.isAccessAllowed && item.price) {
                        if ((typeof e === 'object') && e.cancelable) {
                            e.stopPropagation();
                            e.preventDefault();
                        }

                        const albumId = item.id;
                        GuestAccess.onEnoughMoneyForShop(
                            item.actorId,
                            item.price,
                            item.needAvs,
                            item.needVip,
                            function() {
                                const clb = function(actorId, changedAlbumId) {
                                    if (changedAlbumId === albumId) {
                                        Flux.Gallery.removeGalleryChangeListener(clb);
                                        window.location.href = targetUrl;
                                    }
                                };

                                Flux.Gallery.addGalleryChangeListener(clb);
                                Flux.Gallery.buyAlbum(albumId);
                            },
                            targetUrl
                        );
                    } else {
                        ReloadHelper.reloadWithPjax(targetUrl, e);
                    }
                }
            } else {
                this.props.onButtonClickFn(e, item);
            }
        }
	}

	onClickFn(e, item, picIndex) {
		if (this.props.gridConfig.trackEvents && Flux.Guest.isLoggedIn()) {
			const event = this.eventTracker.getNewEvent();

			event.type    = this.props.gridConfig.trackingType;
			event.context = this.props.gridConfig.trackingContext;
			event.source  = 'www';

			let data = this.props.gridConfig.trackingData;

			if (this.props.gridConfig.mainActor) {
				data += 'actorId=' + this.props.gridConfig.mainActor.id + ';';
			}

			data += 'id=' + item.id + ';';
			data += 'url=' + window.location.href + ';';
			event.data = data;

			this.eventTracker.trackEvent(event);
		}

		if (item.isPinnedPhoto) {
			let realPictureIndex = picIndex;

			// add item count for the current page
			if (this.props.gridConfig &&
				this.props.gridConfig.pagination &&
				this.props.gridConfig.pagination.itemsPerPage &&
				this.props.gridConfig.pagination.currentPage
			) {
				realPictureIndex += (this.props.gridConfig.pagination.currentPage - 1) * this.props.gridConfig.pagination.itemsPerPage;
			}

			const targetUrl = item.targetUrl + '#pjax-scrollto';

			GuestAccess.onContentAccess(
				{
					needAvs:   item.needAvs,
					needVip:   item.needVip,
					needLogin: false,
					targetUrl,
				},
				() => {
					this.onOpenPinnedPictureViewer(realPictureIndex);
				},
				() => {
					this.onOpenPinnedPictureViewer(realPictureIndex);

					if (!this.props.isMobile) {
						ReloadHelper.reloadCurrentPage();
					}
				});

			e.preventDefault();
			e.stopPropagation();
		} else if (item.isLinkOpeningGallery) {
			this.onOpenViewer(picIndex, item);
			e.preventDefault();
			e.stopPropagation();
		} else {
			this.props.onClickFn(e, item, picIndex);
		}
	}

	onOpenViewer(index, item) {
		const clb = (actorId, albumId) => {
			this.renderViewer(actorId, albumId);
			Flux.Gallery.removeGalleryChangeListener(clb);
			// spinner loader
			if (this.props.isMobile) {
				GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, false);
			}
		};

		Flux.Gallery.addGalleryChangeListener(clb);

		// spinner loader
		if (this.props.isMobile) {
			GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, true);
		}

		Flux.Gallery.loadGallery(item.id);

		if (item.mediaType && item.mediaType === 'photo_album') {
			pictureIndex = 0; // show the first pic from the album
		} else {
			pictureIndex = index;
		}
	}

	onOpenPinnedPictureViewer(index) {
		pictureIndex = index;
		const clb    = () => {
			this.renderPinnedPictureViewer();
			Flux.Gallery.removePinnedPhotosChangeListener(clb);
			// spinner loader
			if (this.props.isMobile) {
				GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, false);
			}
		};
		Flux.Gallery.addPinnedPhotosChangeListener(clb);

		// spinner loader
		if (this.props.isMobile) {
			GlobalEventHandler.emit(GlobalEventHandler.LOADER_TOGGLE, true);
		}

		Flux.Gallery.loadPinnedPhotos(this.getPinnedPhotosParams());
	}

	getPinnedPhotosParams() {
		let params;

		// find current sorting
		if (this.props.gridConfig && this.props.gridConfig.sortOptions) {
			params = this.props.gridConfig.sortOptions.reduce((acc, item) => {
				if (item.selected) {
					acc['sort']  = item.sort;
					acc['order'] = item.order;
				}
				return acc;
			}, {});
		}

		return params;
	}

	renderPinnedPictureViewer() {
		const data             = Flux.Gallery.getPinnedPhotos(this.getPinnedPhotosParams());
		const galleryContainer = TileGrid.getGalleryContainer();

		if (data && galleryContainer) {
			render(
                withSuspense(<Viewer actor={data.actor}
				        title={data.title}
				        initialImages={data.photos}
				        initShowModal={true}
				        isMobile={this.props.isMobile}
				        selectedIndex={pictureIndex}
				        initialIsFavoriteOfGuest={data.isModelGuestFavorite}
                        hideVoting={true}
				/>), galleryContainer);
		}
	}

	renderViewer(actorId, albumId) {
		const data = Flux.Gallery.getGalleryData(albumId);
		// not bought content?
		if (data && data.needBuying && data.type !== "video" && data.galleryUrl) {
			if (this.props.isMobile) {
                Flux.VXMobile.navigateTo(data.galleryUrl);
			} else {
				PjaxWrapper.reload(data.galleryUrl + '#pjax-scrollto');
			}
			return false;
		} else if (!data) {
			return false;
		}
		const galleryContainer = TileGrid.getGalleryContainer();

		if (galleryContainer) {
			if (data.type === "video") {
				render(
                    withSuspense(<VideoPlayer actor={data.actor}
					             initialVideos={[data.video]}
					             initShowModal={true}
					             initialIsFavoriteOfGuest={data.isModelGuestFavorite}
					             isMobile={this.props.isMobile}
					             modalClassName={!this.props.isMobile ? ' -min-w-conversion' : ''}
					             showLike={data.actor !== null}
					             showDislike={data.actor !== null}
					             showDownload={data.isDownloadPermitted}
					/>), galleryContainer);
			} else {
				render(
					withSuspense(<Viewer actor={data.actor}
					        title={data.title}
					        initialImages={data.photos}
					        initShowModal={true}
					        isMobile={this.props.isMobile}
					        selectedIndex={pictureIndex}
					        initialIsFavoriteOfGuest={data.isModelGuestFavorite}
                            showLike={false}
					        showDislike={false}
                            hideVoting={true}
					/>), galleryContainer);

			}
		}
	}

	onClickVideoTile(e) {
		ReloadHelper.reloadWithPjax(this.props.routes, e);
	}

	render() {
		//get from grid config
		const isListMode = this.props.gridMode === Flux.Constants.TileGridModes.LIST;
		let cols         = isListMode ? 1 : (this.props.cols || this.props.gridConfig.cols);
		let className    = this.props.initialClassName ? this.props.initialClassName : (isListMode ? 'tile-grid-list' : 'context-box--media-panel row');
		const mainActor  = this.props.gridConfig.mainActor || null;
		if (this.props.landingPageResults) {
			cols = 2;
            if (this.state.windowWidth > TileGrid.Breakpoints.FourCols) {
				cols = 4;
			} else if (this.state.windowWidth > TileGrid.Breakpoints.ThreeCols) {
				cols = 3;
			}
		}
		let numberOfHighlightedActors = 0;
		let numberOfWelcomeClips      = 0;
		let welcomeClipIndex          = 0;

		let placeholderTile = null;
		let colsLG          = Math.min(12, parseInt(12 / cols));
		let colsSM          = 0;
		let colsXS          = Math.min(12, colsLG * 2);

		if (this.props.loader) {
			className += ' h-pos-relative';
		}

		if (this.props.isMobile) {
			colsXS = Math.min(12, parseInt(12 / cols));
			colsLG = Math.ceil(colsXS / 2);
		} else if (parseInt(cols) === 3) {
			// special handling for cols = 3 (to avoid "broken" layout for XS which would lead to cols-sm-8 hence leaving one third of the row empty)
			colsSM = 6;
			colsXS = 12;
		}

		let tileIndex = 0;

		const tiles = (this.props.tiles || this.props.gridConfig.initialTiles || []).map((data, index) => {
			let tileColsLG                        = colsLG;
			let tileColsSM                        = colsSM;
			let tileColsXS                        = colsXS;
			let animateTile                       = false;
			let badgeContainerAdditionalClassName = '';

			if (!this.props.showHighlightGrid && data.type === Tile.type.HIGHLIGHT_ACTOR_GRID_TILE) {
				data.type      = Tile.type.ACTOR_GRID_TILE;
				data.plateType = PlateFactory.type.ACTOR_PLATE;
			}

			// special for big Highlight tile
			if (Tile.isHighlightTile(data.type)) {
				tileColsXS  = Math.min(12, tileColsXS * 2);
				tileColsSM  = Math.min(12, tileColsSM * 2);
				tileColsLG  = Math.min(12, tileColsLG * 2);
				animateTile = data.type !== Tile.type.HIGHLIGHT_TEASER_GRID_TILE;

				if (this.props.alignHighlightBadgesLeft) {
					badgeContainerAdditionalClassName = ' -always-visible';
				}
			} else if (data.type === Tile.type.ACTOR_GRID_TILE && this.props.isMobile) {
				badgeContainerAdditionalClassName = ' -always-visible-success';
			} else if (data.type === Tile.type.PROSEARCH_WELCOME_CLIP_ROTATOR_TILE) {
				tileColsXS = 12;
				tileColsLG = 12;
				tileColsSM = 12;
				numberOfWelcomeClips++;
				welcomeClipIndex = index;
			}

			const cols = [];

			if (this.props.landingPageResults && data.type !== Tile.type.PROSEARCH_WELCOME_CLIP_ROTATOR_TILE) {
				cols.push('col-xs-6');
				cols.push('col-sm-4');
				cols.push('col-md-' + tileColsLG);

				if (Tile.isHighlightTile(data.type)) {
					cols.push('col-lg-25');
					numberOfHighlightedActors++;
				} else {
					cols.push('col-lg-3');
				}
			} else if (this.props.useResponsiveCols) {
				cols.push('col-xs-' + tileColsXS);

				if (tileColsSM > 0) {
					cols.push('col-sm-' + tileColsSM);
				}

				cols.push('col-lg-' + tileColsLG);
			} else {
				// use fixed col count no matter what resolution
				cols.push('col-xs-' + tileColsLG);
			}

			let gridTileClassName     = isListMode ? 'tile-grid-list--row' : cols.join(' ');
			let searchGridBottomStyle = '';
			if (data.type === Tile.type.PHOTO_ALBUM_GRID_TILE && this.props.gridMode === TILEGRID_MODE_GRID) {
				searchGridBottomStyle = 'tile-grid-margin-search-results';
			}

			gridTileClassName += ' ' + searchGridBottomStyle;
			if (data.type === Tile.type.PROSEARCH_NO_RESULTS_GRID_TILE) {
				gridTileClassName += ' col-separator col-separator--full';
			} else if (data.type === Tile.type.PROSEARCH_SEPARATOR_GRID_TILE) {
				gridTileClassName += ' col-separator';

				if (this.state.windowWidth > TileGrid.Breakpoints.FiveCols) {
					switch (this.props.totalHits) {
						case 2:
							gridTileClassName += " col-separator--2";
							break;
						case 1:
							gridTileClassName += " col-separator--1";
							break;
						default:
							gridTileClassName += " col-separator--full";
					}

					if (this.props.totalHits > 7) {
						if ((this.props.totalHits - 7) % 5 === 2) {
							gridTileClassName += " col-separator--2";
						}
						if ((this.props.totalHits - 7) % 5 === 1) {
							gridTileClassName += " col-separator--1";
						}
					}
				} else if (this.state.windowWidth > TileGrid.Breakpoints.FourCols) {
					if ((this.props.totalHits - 5) % 4 === 2) {
						gridTileClassName += " col-separator--2";
					} else if ((this.props.totalHits - 5) % 4 === 1) {
						gridTileClassName += " col-separator--1";
					} else {
						gridTileClassName += " col-separator--full";
					}
				} else {
					gridTileClassName += " col-separator--full";
				}

			}

			return (
				<Tile
					key={generateKey('grid-tile-' + data.tileIdentifier, index)}
					animateTile={animateTile}
					autoAnimateInView={this.props.autoAnimateInView}
					badgeContainerAdditionalClassName={badgeContainerAdditionalClassName}
					gridConfig={this.props.gridConfig}
					gridTileClassName={gridTileClassName}
					guestIsLoggedIn={this.props.guestIsLoggedIn}
					proSearchActiveCategory={this.props.proSearchActiveCategory}
					tileData={data}
					tileIndex={tileIndex++}
					isMobile={this.props.isMobile}
					useTileWithSecondary={this.props.useTileWithSecondary}
					contentBoxClassModifier={this.props.contentBoxClassModifier}
					onButtonClickFn={this.onButtonClickFn}
					onClickFn={this.onClickFn}
					gridMode={this.props.gridMode}
					hasMultipleItems={this.props.tilesHaveMultipleItems}
					showBadges={this.props.showBadges}
					useThumbsVoting={this.props.useThumbsVoting}
					totalHits={this.props.totalHits}
					lazyImageLoading={this.props.lazyImageLoading}
					headlineType={this.props.headlineType}
				/>
			);
		});

		if (this.props.isVideoDetailsPage && tiles.length >= 4) {
			const gridConfig = {
				...this.props.gridConfig,
				navigateTo: this.props.navigateTo,
			};
			const tl = (
				<Tile
					key={'grid-tile-' + Date.now()}
					gridConfig={gridConfig}
					guestIsLoggedIn={this.props.guestIsLoggedIn}
					proSearchActiveCategory={this.props.proSearchActiveCategory}
					gridTileClassName="col-xs-6 col-lg-3"
					tileData={{
						plateType: PlateFactory.type.NO_PLATE,
						type:      Tile.type.VIDEO_ACTORS_TILE,
						actorName: mainActor.name,
					}}
					tileIndex={tileIndex++}
					isMobile={this.props.isMobile}
					gridMode={this.props.gridMode}
					onClickFn={this.onClickVideoTile}
					hasMultipleItems={this.props.tilesHaveMultipleItems}
					useThumbsVoting={this.props.useThumbsVoting}
					lazyImageLoading={this.props.lazyImageLoading}
				/>
			);

			const writeMessageGrid = (
                withSuspense(
				<ActorPlaceholderTile
					gridCols={this.props.isMobile ? 1 : cols}
					placeholderCols={this.props.isMobile ? 1 : (cols - (tiles.length % cols))}
					gridTileClassName={'col-xs-' + ((tiles.length % 2 === 0) ? 12 : 6) + " col-lg-" + Math.min(12, ((this.props.isMobile ? 1 : (cols - (tiles.length % cols))) * (12 / (this.props.isMobile ? 1 : cols))))}
					hasVideos={true}
					actorName={mainActor.name}
					actorId={mainActor.id}
					routes={this.props.routes}
					isMobile={this.props.isMobile}
					Link={this.props.Link}
					guestIsLoggedIn={this.props.guestIsLoggedIn}
					textMissing={this.props.gridConfig.textMissing}
					searchTags={this.props.gridConfig.searchTags}
					onResetClickFn={this.props.onResetClickFn}
				/>)
			);

			if (tiles.length < 15) {
				tiles.push(writeMessageGrid);
			} else if (tiles.length === 15) {
				tiles.push(tl);
			} else {
				tiles[tiles.length - 1] = tl;
			}
		}

		// fill with dummy tiles to properly align tiles within flex-box
		if (!this.props.landingPageResults && this.props.dummyTileClass && tiles.length % cols !== 0) {
				for (let t = 0; t < (tiles.length % cols); t++) {
					tiles.push(<div className={'col-xs-' + colsXS + ' col-lg-' + colsLG + ' ' + this.props.dummyTileClass}/>);
				}
			}
		if (this.props.landingPageResults) {
			let numberOfGrids = tiles.length - numberOfWelcomeClips;
			if (cols === 4 || cols === 5) {
				numberOfGrids = numberOfGrids + numberOfHighlightedActors * 3;
			}
			if (this.state.crop) {
				while (numberOfGrids % cols !== 0) {
					tiles.pop();
					numberOfGrids--;
				}
			}

			(cols === 4 && numberOfWelcomeClips === 1) ? tiles.splice(welcomeClipIndex - 1, 0, tiles[tiles.length - 1]) : null;
		}

		let loader = '';

		if (this.props.loader) {
			loader = this.props.loader;
		}

		return (
			<div className={className}>
				{loader}
				{tiles}
				{placeholderTile}
			</div>
		);
	}
}

TileGrid.Breakpoints = Flux.Constants.TileGridBreakpoints;

TileGrid.propTypes = {
	autoAnimateInView:        PropTypes.bool,
	cols:                     PropTypes.number,
	contentBoxClassModifier:  PropTypes.string,
	dummyTileClass:           PropTypes.string,
	gridConfig:               PropTypes.object.isRequired,
	gridMode:                 PropTypes.string,
	guestIsLoggedIn:          PropTypes.bool.isRequired,
	isMobile:                 PropTypes.bool,
	initialClassName:         PropTypes.string,
	maxEntriesForPlaceholder: PropTypes.number,
	navigateTo:               PropTypes.func,
	totalHits:                PropTypes.number,
	onButtonClickFn:          PropTypes.func,
	onClickFn:                PropTypes.func,
	onResetClickFn:           PropTypes.func,
	tiles:                    PropTypes.array,
	proSearchActiveCategory:  PropTypes.string,
	useResponsiveCols:        PropTypes.bool,
	useTileWithSecondary:     PropTypes.bool,
	tileGridId:               PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	loader:                   PropTypes.string,
	routes:                   PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	showAmateurSearchButton:  PropTypes.bool,
	Link:                     PropTypes.object,
	landingPageResults:       PropTypes.bool,
	isVideoActor:             PropTypes.bool,
	isVideoDetailsPage:       PropTypes.bool,
	tilesHaveMultipleItems:   PropTypes.bool,
	alignHighlightBadgesLeft: PropTypes.bool,
	showHighlightGrid:        PropTypes.bool,
	showBadges:               PropTypes.bool,
	useThumbsVoting:          PropTypes.bool,
	lazyImageLoading:         PropTypes.bool,
	headlineType:             PropTypes.oneOf(Object.values(Flux.Constants.Headlines)),
};

TileGrid.defaultProps = {
	autoAnimateInView:        false,
	gridMode:                 Flux.Constants.TileGridModes.GRID,
	isMobile:                 false,
	onClickFn:                () => {},
	useResponsiveCols:        true,
	isVideoActor:             false,
	isVideoDetailsPage:       false,
	tilesHaveMultipleItems:   false,
	alignHighlightBadgesLeft: false,
	showHighlightGrid:        true,
	showBages:                false,
	useThumbsVoting:          false,
	totalHits:                0,
	lazyImageLoading:         false,
};

export default TileGrid;
